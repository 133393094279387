import { BoutiqueData } from '@innedit/innedit';
import React, { FC } from 'react';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import CMSView from '../../../../../CMS/components/View';
import Form from '../../../../../CMS/containers/Boutique/components/new/Form';
import params from '../../params.json';

const CommandeForm: FC<BoutiqueProps> = ({ boutique, user }) => (
  <CMSView>
    <Form
      displayClose={false}
      displayNavigation={false}
      displayRemove={false}
      docId={boutique.id}
      hideSubmitButton
      itemPathnamePrefix="/admin/boutiques/"
      model={new BoutiqueData({ params })}
      name="boutique"
      user={user}
    />
  </CMSView>
);

export default requireBoutique(CommandeForm);
