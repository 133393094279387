import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import ParamsForm from '../../core/sysext/Boutique/containers/Params/components/Form';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const BoutiqueParamsPage: FC<PageProps> = props => {
  const { location } = props;

  return (
    <Backoffice location={location}>
      <ParamsForm {...props} />
    </Backoffice>
  );
};

export default BoutiqueParamsPage;
